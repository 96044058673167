/* scrollbar */
@import 'simplebar-react/dist/simplebar.min.css';

/* react-responsive-carousel */
@import "react-responsive-carousel/lib/styles/carousel.min.css";



.loginSlider .item {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  /* padding-bottom: 14px; */
  gap: 0px;
}

/* .loginSlider.owl-theme .owl-dots .owl-dot {
  width: 25px;
  height: 6px;
  background: #BBDEFB;
} */
.loginSlider.owl-theme .owl-dots .owl-dot.active span,
.loginSlider.owl-theme .owl-dots .owl-dot:hover span {
  background: #c5dcee;
}

.loginSlider.owl-theme .owl-dots .owl-dot span {
  width: 30px;
  height: 4px;
  background: #757575;
  margin: 5px 4px;
}

.passwordValidationList ul li.active svg path,
.passwordValidationList ul li.active {
  fill: #67AD5B;
  color: #67AD5B;
}

.themeSwith {
  content: "";
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1;
}

/* Manage carousel prev next btns css */
.owl-nav {
  justify-content: space-between;
  display: flex;
  padding: 0 30px 0 30px;
  position: relative;
  top: -50%;
}

.owl-carousel .owl-nav button.owl-prev span {
  color: #757575;
  font-size: 38px;
  font-weight: 700;
}

.owl-carousel .owl-nav button.owl-next span {
  color: #757575;
  font-size: 38px;
  font-weight: 700;
}

.owl-carousel .owl-nav button.owl-prev:hover,
.owl-carousel .owl-nav button.owl-next:hover {
  background-color: transparent;
}