.container {
    position: fixed;
    bottom: 0.8em;
    left: 3.9em;
}

.menu-toggle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: transparent;
    position: absolute;
    z-index: 5;
    bottom: 10px;
    right: 12px;
    display: table;
    text-align: center;
}

.menu-toggle .fa {
    color: #fff;
    display: table-cell;
    vertical-align: middle;
    transition: 0.4s;
}

.btn-app {
    width: 2.5em;
    height: 2.5em;
    border-radius: 50%;
    position: absolute;
    background-color: #0C8CE9;
    color: #fff;
    text-align: center;
}

.menu-line {
    position: absolute;
    z-index: 2;
}

.menu-line .btn-app {
    bottom: 0;
    right: 0.65em;
    transition: 0.3s;
    transition-delay: 0.5s;
}

.changeColor {
    background-color: #f8f8f8;
}

/* .menu-line.open .btn-app:nth-of-type(1) {
    bottom: 2.25em;
}

.menu-line.open .btn-app:nth-of-type(1) {
    bottom: 4.25em;
}

.menu-line.open .btn-app:nth-of-type(2) {
    bottom: 7.25em;
}

.menu-line.open .btn-app:nth-of-type(3) {
    bottom: 10.25em;
}

.menu-line.open .btn-app:nth-of-type(4) {
    bottom: 13.25em;
}

.menu-line.open .btn-app:nth-of-type(5) {
    bottom: 16.25em;
} */